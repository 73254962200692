/* Interface.css */

.chat-interface {
  background-color: #111; /* Dark background for the chat interface */
  color: #fff; /* Light text for visibility on the dark background */
  padding: 10px;
}

.delete-conversation {
  position: absolute;
  top: 60px;
  right: 10px;
  font-size: 12px;
  color: #fff; /* red */
}


.tabs {
  display: flex; /* Flex container for the tabs */
  flex-wrap: nowrap; /* Prevent wrapping of items */
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Prevents vertical scrolling */
  gap: 10px; /* Spacing between buttons */
  -webkit-overflow-scrolling: touch; /* Momentum-based scrolling on iOS devices */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  z-index: 10;
}

.tabs::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.tabs button {
  background-color: #333; /* Darker background for buttons */
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px; /* Rounded corners */
  font-size: 13px;
  cursor: pointer;
  outline: none;
  border: 1px solid #555; /* Slight border for buttons */
  transition: background-color 0.3s ease; /* Transition for hover effect */
}

.tabs button:hover {
  background-color: #555; /* Hover effect */
}

.tabs button.active {
  background-color: #555; /* Active tab background */
  border-color: #777; /* Different border color for active tab */
}

