/* Loading dots animation */
.loading-dots {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 30px; /* Vertical spacing from preceding content */
  }
  
  .loading-dots div {
    background-color: rgb(114, 246, 156); /* Bright green for visibility */
    width: 4px; /* Small dot size */
    height: 4px; /* Equal height and width for circular shape */
    margin: 0 2px; /* Spacing between dots */
    border-radius: 50%; /* Fully rounded to create dots */
    animation: jump 0.4s infinite alternate; /* Jumping animation for loading effect */
  }
  
  .loading-dots div:nth-child(2) {
    animation-delay: 0.2s; /* Staggered animation start for second dot */
  }
  
  .loading-dots div:nth-child(3) {
    animation-delay: 0.4s; /* Staggered animation start for third dot */
  }



@keyframes jump {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
