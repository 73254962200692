/* App.css */

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #000;
  color: white;
  overflow: hidden;
}
.App-logo {
  height: 30px; /* Adjust logo size if necessary */
  /* Other styles as needed */
  margin-bottom: 15px;
}

.App-header {
  background-color: #000;
  padding: 20px; /* Add padding at the top */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align children to the left */
  padding-top: 20px; /* Adds space at the top */
  padding-right: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  width: 46.7%; /* Set the width to be 50% of its parent */
  position: relative; /* Add this to ensure it's positioned correctly */
  z-index: 2; /* Ensures it stacks on top if needed */

  position: absolute; /* Position it absolutely within the .App div */
  left: 0;
  top: 0;

}

.main-content {
  display: flex;
  flex: 1;
  position: relative; /* Add this for positioning context */
}

.graph-container {
  flex: 1; /* Takes remaining space */
  overflow: hidden;
}

.chat-interface {
  flex: 1; /* Takes remaining space */
  overflow: hidden;
  z-index: 1;
  }
  
  @media (max-width: 767px) {
    .graph-container {
      display: none; /* Hides the graph container on small screens */
    }
    .App-header {
      display: none;
    }

  
    .chat-interface {
      width: 100%; /* Makes the chat interface take full width */
    }
  }
  