/* General Chat Session CSS */
.chat-session {
  background-color: #111; /* Dark background for the chat session */
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto; /* Center it horizontally */
  max-height: calc(100vh - 100px);
}

.message-plots img {
  max-width: 95%;
  margin: 2.5%;
}

.messages {
  overflow-y: auto; /* Allows vertical scrolling */
  flex-direction: column; /* Ensure messages are stacked vertically */
  margin-bottom: 50px;
  max-height: calc(100vh - 50px); /* Adjust the 100px based on other elements' total height */
  flex-grow: 1; 
}

.message {
  position: relative;
  padding-left: 40px; /* Make room for the avatar on the left */
  margin-top: 10px; /* Ensure space between messages */
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 80%; /* Allows some space on sides for tail effect if needed */
}

.message-header {
  font-weight: bold; /* Make the user name bold */
  margin-bottom: 4px; /* Space between the name and the message text */
}

.message.me {
  display: flex;
  align-items: center;
  padding-left: 12px; /* No avatar on the left for user messages */
  padding-right: 40px; /* Make room for the avatar on the right */
}

.message.ai {
  background-color: #e5e5ea; /* Light color for bot messages */
  align-self: flex-start;
  margin-left: 10px; /* Give some space for the message tail if you have one */
  color: black; /* Text color for bot messages */
}

/* Code block styling */
.message-content pre {
  font-size: 0.85em; /* Adjust the font size as needed */
  background-color: #2e2e2e; /* Slightly darker background for better contrast */
  padding: 8px;
  border-radius: 4px;
  white-space: pre-wrap; /* Ensure long lines wrap within the container */
  word-wrap: break-word; /* Break long words if necessary */
  max-width: 100%; /* Ensure the code block doesn't exceed the container width */
}

/* New styles for scrollable table container */
.scrollable-table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text from wrapping */
  max-width: 100%; /* Ensure it doesn't overflow the container */
  margin: 0 auto; /* Center the table content */
}

.scrollable-table-container pre {
  white-space: pre; /* Keep the preformatted text formatting */
  margin: 0; /* Optional: to remove any default margin */
}

.input-container {
  position: fixed; /* Fix position to the bottom of the screen */
  bottom: 0; /* Align to the bottom */
  right: 0; /* Align to the right to stretch across the screen */
  display: flex;
  width: 49%;
  padding: 10px; /* Add some padding */
  background-color: #111; /* Match the chat session background color */
  z-index: 100; /* Make sure it's above other content */
}

.chat-input {
  flex-grow: 1;
  border-radius: 20px;
  padding: 10px;
  margin-right: 10px;
}

.send-button {
  background-color: #555; /* Bootstrap primary color for send button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #555; /* Slightly darker on hover */
}

.info-icon {
  font-size: 0.9em;
  color: #007bff;
}

.explained-text {
  margin-top: 5px;
  padding: 10px;
  background-color: #2e2e2e;
  border-left: 3px solid #ffffff;
}

.square-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: white;
  margin-left: 5px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .input-container {
    width: 95%;
  }
  .chat-input {
    font-size: 16px; /* Ensure this is set for smaller devices too */
  }
}
