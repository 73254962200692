/* styles/Popup.css */

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything else */
}

.popup {
  background-color: #000; /* Black background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  width: 80%; /* You might want to set a max-width here as well */
  max-width: 600px;
}

.search-input {
  width: calc(100% - 40px); /* Subtract padding */
  padding: 10px 20px;
  margin-bottom: 20px; /* Space between search input and the list */
  border-radius: 20px;
  border: 1px solid #333;
  background-color: #1a1a1a; /* Slightly lighter black for input */
  color: #fff; /* White text color */
  font-size: 16px; /* Increase font size */
}
.tab-list-item {
  margin: 0;
  border: none;
  background-color: #333; /* Dark grey background */
  border-radius: 10px;
  transition: background-color 0.3s;
  display: block; /* Make the list item behave like a block */
  padding: 0; /* No padding here, the button will have padding */
}

.tab-list-item button {
  display: block; /* Stretch to fill the container */
  width: 100%; /* Ensure button fills its container */
  text-align: left; /* Align text to the left */
  background: none;
  border: none;
  color: white;
  padding: 10px 20px; /* Padding to make the button easier to click */
  cursor: pointer;
  border-radius: 10px; /* Rounded corners to match the li */
}

.tab-list-item:hover, .tab-list-item button:hover {
  background-color: #555; /* Hover effect for both li and button */
}

.popup button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
}

.popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px; /* Set a max height */
  overflow-y: auto; /* Enable scroll if list is too long */
}

.popup li {
  margin-bottom: 10px;
  background-color: #333; /* Slightly lighter background for list items */
  border-radius: 10px;
  padding: 10px;
  transition: background-color 0.3s;
}

.popup li:hover {
  background-color: #555; /* Hover effect for list items */
}