/* Assuming the rest of the body styles are already in place */

.slider-container,
.slider-container label,
.slider-container span {
  color: #fff; /* Set text color to white */
}
.slider-container {
  padding: 20px 20px; /* Add horizontal padding */
  margin: 0 auto; /* Center the slider in case it's within a flex or block container */
}


/* Add the range styles directly, without nesting */
input[type="range"] {
  width: calc(100% - 40px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 200px;
  background-color: transparent;
}

input[type="range"]:focus {
  outline-color: #A4F76A;
}

/* Webkit slider track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 3px;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgb(148, 148, 148) 50%,
    rgba(53, 92, 125, 1) 100%
  );
}

/* Mozilla slider track */
input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 3px;
  background: linear-gradient(
    to right,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
}

/* MS slider track */
input[type="range"]::-ms-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 3px;
  background: linear-gradient(
    to right,
    rgba(246, 114, 128, 1) 0%,
    rgba(192, 108, 132, 1) 50%,
    rgba(53, 92, 125, 1) 100%
  );
}

/* Webkit slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 8px;
  background: #222 url("http://codemenatalie.com/wp-content/uploads/2019/09/slider-thumb.png") center no-repeat;
  background-size: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: grab;
}

input[type="range"]::-webkit-slider-thumb:active {
  cursor: grabbing;
}

/* Mozilla slider thumb */
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #A4F76A;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 8px;
  background: #222 url("http://codemenatalie.com/wp-content/uploads/2019/09/slider-thumb.png") center no-repeat;
  background-size: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: grab;
}

input[type="range"]::-moz-range-thumb:active {
  cursor: grabbing;
}

/* MS slider thumb */
input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #A4F76A;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 8px;
  background: #222 url("http://codemenatalie.com/wp-content/uploads/2019/09/slider-thumb.png") center no-repeat;
  background-size: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  cursor: grab;
}

input[type="range"]::-ms-thumb:active {
  cursor: grabbing
}

